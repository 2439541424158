// extracted by mini-css-extract-plugin
export var root = "Blog-module--root--2xXeB";
export var worksList = "Blog-module--worksList--1yHBV";
export var worksListItem = "Blog-module--worksListItem--zZf6q";
export var worksTabs = "Blog-module--worksTabs--3lW28";
export var worksTabItem = "Blog-module--worksTabItem--38Wyb";
export var worksTabTitle = "Blog-module--worksTabTitle--250D3";
export var worksTabImage = "Blog-module--worksTabImage--gwqiJ";
export var worksTabCategory = "Blog-module--worksTabCategory--1WIJX";
export var worksTabSubTitle = "Blog-module--worksTabSubTitle--x8wVj";
export var worksTabBig = "Blog-module--worksTabBig--YF4_Z";
export var worksTabSmall = "Blog-module--worksTabSmall--1_rq7";
export var title = "Blog-module--title--1iK0r";