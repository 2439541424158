import React from 'react'

import Layout from '~components/Layout'
import BlogTemplate from '~templates/Blog';

export default function IndexPage() {
  return (
    <Layout>
      <BlogTemplate />
    </Layout>
  )
}
