import React, { FC, ReactElement, useState } from 'react'

import { graphql, Link, useStaticQuery } from 'gatsby';
import {
  root,
  contentWrapper,
  title,
  worksList,
  worksListItem,
  worksTabImage,
  worksTabItem,
  worksTabCategory,
  worksTabTitle,
  worksTabs as worksTabsStyles,
  worksTabSubTitle
} from './Blog.module.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from '~components/Button';
import LinkButton from '~components/LinkButton';

const BlogTemplate: FC<unknown> = (): ReactElement => {
  const [activeCategory, setActiveCategory] = useState(``);
  const data = useStaticQuery(query);
  const header = data.allContentfulBlogPage?.nodes[0];
  const categories = data.allContentfulBlogCategory?.nodes.sort((a, b) => {
    if (b.title === 'All') return 0;
    if (a.title === 'All' || a.title < b.title) { return -1; }
    if (a.title > b.title) { return 1; }
    return 0;
  });
  const posts = data.allContentfulBlogPost?.nodes;

  const filterCategory = (cat: string) => {
    if (cat === 'All') setActiveCategory(``);
    else setActiveCategory(cat);
  }

  return (
    <div className={root}>
      <h1 className={title}>
        {documentToReactComponents(JSON.parse(header.richTitle.raw))}
      </h1>
      <p>{header.desc}</p>
      <div className={contentWrapper}>
        <div className={worksList}>
          {categories.map((category: any, index: number) => (
            <LinkButton
              key={index}
              className={worksListItem}
              label={category.title}
              handleClick={() => { filterCategory(category.title); }}
            />
          ))}
        </div>
        <div className={worksTabsStyles}>
          {posts
            .filter(post => !activeCategory || post.category[0].title === activeCategory)
            .map((post: any, index: number) => {
            const image = getImage(post.image)
            return (
              <Link to={`/blog/${post.slug}`} key={index} className={worksTabItem}>
                {image && (
                  <GatsbyImage
                    alt={``}
                    image={image}
                    className={worksTabImage}
                  />
                )}
                <div>
                  <div className={worksTabCategory}>{post.category[0].title}</div>
                  <div className={worksTabTitle}>{post.title}</div>
                  <div className={worksTabSubTitle}>{post.description.description}</div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BlogTemplate


export const query = graphql`
query allContentfulBlogPageQuery {
  allContentfulBlogPage {
    nodes {
      richTitle {
        raw
      }
      desc
    }
  }
  allContentfulBlogCategory {
    nodes {
      title
    }
  }
  allContentfulBlogPost(
    sort: {fields: category___blogpost___published, order: DESC}
  ) {
    nodes {
      title
      slug
      category {
        title
      }
      description {
        description
      }
      content {
        raw
      }
      image {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 98
          placeholder: TRACED_SVG
        )
      }
    }
  }
}
`
